import PropTypes from 'prop-types';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import Footer from 'components/Footer';
import Header from 'components/Header';
import withAuthRequired from 'components/auth/withAuthRequired';
import MyAccountNavigation from 'templates/account/MyAccountNavigation';

const ChangePasswordOverlay = loadable(() => import('components/overlays/ChangePasswordOverlay'));

const GridMainLayout = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    padding: ['8px', null, '0 18px 18px 18px'],
});

const Wrapper = styled('div', {});

const AccountLayout = ({ children, page = {} }) => {
    const [css] = useStyletron();
    const AccountAuthWrapper = withAuthRequired(Wrapper);
    const alternateHrefs = page.data?.hrefs || [];

    return (
        <div>
            <Header alternateHrefs={alternateHrefs} theme="default" />
            <div className={css({ minHeight: '100vh' })}>
                <AccountAuthWrapper>
                    <div
                        className={css({
                            display: 'grid',
                            gridTemplateColumns: 'repeat(12, 1fr)',
                            gap: '12px',
                            position: 'relative',
                            backgroundColor: 'var(--color-bg-account)',
                        })}
                    >
                        <MyAccountNavigation />
                        <main
                            className={css({
                                flex: '1 0 auto',
                                gridColumn: ['1 / span 12', null, '3 / span 10'],
                                zIndex: 1,
                            })}
                        >
                            <GridMainLayout>{children}</GridMainLayout>
                        </main>
                        <AnimatedOverlay
                            lockScroll
                            $style={{ height: 'auto', top: '50%' }}
                            animation="slideBTM"
                            animationDuration="none"
                            component={ChangePasswordOverlay}
                            id="change-password"
                        />
                    </div>
                </AccountAuthWrapper>
            </div>
            <Footer alternateHrefs={alternateHrefs} />
        </div>
    );
};

AccountLayout.propTypes = {
    children: PropTypes.node,
    page: PropTypes.object.isRequired,
};

export default AccountLayout;
