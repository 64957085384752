import { useDispatch, useSelector } from 'react-redux';
import fm from 'format-message';
import { getButtonTheme, themes } from 'config/branding/component-themes/button-themes';
import textStyles from 'config/branding/textStyles';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import { matchesMedia } from '@activebrands/core-web/utils/responsive';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import OverlayToggler from 'components/Header/OverlayToggler';
import LogOutIcon from 'components/icons/LogOutIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    bottom: 'var(--height-header)',
    padding: '12px',
    position: 'fixed',
    width: '100%',
    zIndex: 'calc(var(--zindex-header) - 2)',
    background: 'linear-gradient(var(--color-bg-gradient-meny) 0%, white 100%)',

    [media.min['mobile.lg']]: {
        backgroundColor: 'var(--color-bg-secondary)',
        gridColumn: '1 / span 2',
        position: 'sticky',
        margin: '8px',
        width: '100%',
        height: `calc(100dvh - var(--height-header) - 8px)`, // -8px is for the gap between header and overlay
        minWidth: '220px',
        borderRadius: '8px',
    },

    [media.min['desktop.sm']]: {
        top: 'calc(var(--height-header) + 8px)',
        zIndex: 0,
        background: 'var(--color-bg-navigation-account)',
    },
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const MyAccountNavigation = () => {
    const [css] = useStyletron();
    const { internalMenu, welcomeLabel, externalMenu } = useAccountQuery();
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const firstName = { '{firstName}': user.firstname || user.email };
    const replacedName = stringReplace(welcomeLabel, firstName);

    const isDesktop = matchesMedia(media.min['desktop.sm']);
    const linkThemeStyling = getButtonTheme(themes[isDesktop ? 'link' : 'defaultsecondary']);

    const activeClass = css({
        color: ['var(--color-text-active-navigation)', null, 'var(--color-link)'],
        backgroundColor: ['var(--color-bg-navigation-active)', null, 'transparent'],
    });

    return (
        <Wrapper>
            <div
                className={css({
                    marginBottom: '28px',
                    paddingBottom: '12px',
                    display: ['none', null, 'block'],
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <Paragraph
                    $style={{ marginBottom: '20px', fontSize: '12px' }}
                    as="span"
                    fontKeys="Misc/14_100_-1_500_UC"
                >
                    {replacedName}
                </Paragraph>
            </div>
            <ScrollableBox
                as="nav"
                className={css({
                    height: ' calc(100% - var(--height-header))',
                    display: 'flex',
                    flexDirection: ['row', null, 'column'],
                    justifyContent: 'space-between',
                })}
                scrolldirection="horizontal"
            >
                <ul
                    className={css({
                        display: 'flex',
                        flexDirection: ['row', null, 'column'],
                        gap: ['4px', null, '16px'],
                    })}
                >
                    <li
                        className={css({
                            height: '40px',
                            width: '100%',
                            borderRadius: '40px',
                            backgroundColor: ['var(--color-bg-secondary)', null, 'transparent'],
                            display: ['block', null, 'none'],
                            padding: '0 8px',
                            border: '1px solid var(--color-border-account)',
                        })}
                    >
                        <OverlayToggler overlayId="account-navigation">
                            <div
                                className={css({
                                    display: 'flex',
                                    gap: '8px',
                                    ...textStyles['Misc/14_100_-1_500_UC'],
                                })}
                            >
                                <Paragraph>{fm('Menu')}</Paragraph>
                            </div>
                        </OverlayToggler>
                    </li>
                    {internalMenu &&
                        internalMenu.map((internalMenuItem, index) => {
                            return (
                                <li
                                    className={css({
                                        height: ['40px', null, '36px'],
                                        width: '100%',
                                        borderRadius: ['50px', null, '0px'],
                                    })}
                                    key={index}
                                >
                                    <Link
                                        activeClassName={activeClass}
                                        className={css({
                                            display: 'inline-flex',
                                            borderRadius: '100px',
                                            borderStyle: 'solid',
                                            borderWidth: '1px',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            whiteSpace: 'nowrap',
                                            border: isDesktop ? 'none' : '1px solid var(--color-border-account)',
                                            padding: ['13px 12px', null, '0'],
                                            ...responsiveTextStyles([
                                                'Misc/14_100_-1_500_UC',
                                                null,
                                                'Primary/24_94_-1_700',
                                            ]),
                                            ...linkThemeStyling,

                                            ':hover': {
                                                color: 'var(--color-base-brand-red)',
                                            },
                                        })}
                                        rel="nofollow"
                                        to={internalMenuItem.link.url}
                                        onClick={overlay.close('account-navigation')}
                                    >
                                        {internalMenuItem.label}
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
                <ul
                    className={css({
                        flexDirection: ['row', null, 'column'],
                        gap: '16px',
                        display: ['none', null, 'flex'],
                        marginBottom: '4px',
                    })}
                >
                    {externalMenu &&
                        externalMenu.map((externalMenuItem, index) => {
                            const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                            return (
                                <li
                                    className={css({
                                        height: '36px',
                                        width: '100%',
                                        borderRadius: '40px',
                                    })}
                                    key={index}
                                >
                                    <Link
                                        activeClassName={activeClass}
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                        })}
                                        rel="nofollow"
                                        to={externalMenuItem.link.url}
                                        onClick={overlay.close('account-navigation')}
                                    >
                                        {icon && <Icon src={icon} />}
                                        <Paragraph as="p" fontKeys="Misc/14_100_500">
                                            {externalMenuItem.label}
                                        </Paragraph>
                                    </Link>
                                </li>
                            );
                        })}
                    <li
                        className={css({
                            height: '36px',
                            marginBottom: '25px',
                            width: '100%',
                            borderTop: '1px solid var(--color-border-account)',
                            paddingTop: '18px',
                        })}
                    >
                        <Link
                            activeClassName={activeClass}
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                            })}
                            rel="nofollow"
                            to="/"
                            onClick={() => dispatch(signOut())}
                        >
                            <LogOutIcon $style={{ marginRight: '8px' }} />
                            <Paragraph as="p" fontKeys="Misc/14_100_500">
                                {fm('Log out')}
                            </Paragraph>
                        </Link>
                    </li>
                </ul>
            </ScrollableBox>
        </Wrapper>
    );
};

export default MyAccountNavigation;
